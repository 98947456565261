import type { FunctionConfig } from "utils/functionsTypes";
import type { Address as AddressType } from "viem";

export const pendleV2RedeemRewards: FunctionConfig<{ user: AddressType }> = {
  signature: "function redeemRewards(address user)",
  Description() {
    return null;
  },

  Label() {
    return <>Claim Rewards on Pendle</>;
  },
};
