import { Assertion } from "@enzymefinance/sdk/Utils";
import { type Hex, decodeFunctionData, getFunctionSignature, parseAbiItem, slice } from "viem";
import { type TransactionConfigKeyType, transactionConfigs } from "../components/transactions/TransactionFunctionTypes";
import type { Inputs } from "./functionsTypes";

export function decodeTransactionData<Tinputs extends Inputs>(data: Hex) {
  try {
    const sigHash = slice(data, 0, 4);

    const transactionConfiguration =
      sigHash in transactionConfigs ? transactionConfigs[sigHash as TransactionConfigKeyType] : undefined;

    // Return undefined to display the default transaction description
    if (!transactionConfiguration) {
      return undefined;
    }

    const fragment = parseAbiItem(transactionConfiguration.signature);
    const shortSignature = getFunctionSignature(transactionConfiguration.signature);

    Assertion.invariant(fragment.type === "function", "fragment is not a function");

    const { args } = decodeFunctionData({
      abi: [fragment],
      data,
    });

    // TODO: for loop
    const inputs = fragment.inputs.reduce((carry, item, index) => {
      const key = item.name;
      const value = args?.[index];
      Assertion.invariant(key !== undefined, "name is undefined");
      Assertion.invariant(value !== undefined, "value is undefined");

      return { ...carry, [key]: value };
    }, {} as Tinputs);

    return { ...transactionConfiguration, shortSignature, fragment, sigHash, inputs };
  } catch (error) {
    console.error(error);

    throw new Error();
  }
}
