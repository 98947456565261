import type { Address } from "@enzymefinance/environment";
import { Network, networks } from "@enzymefinance/environment";
import * as Sentry from "@sentry/react";
import { extractCallError } from "utils/error";
import type { GasRelayerResponseData } from "utils/hooks/useGasRelayerData";
import type { DoneInvokeEvent, ErrorExecutionEvent, Interpreter, MachineConfig, MachineOptions } from "xstate";
import { assign, createMachine } from "xstate";

import {
  type CallErrorType,
  type Hex,
  type PublicClient,
  type WalletClient,
  encodeAbiParameters,
  isAddress,
  isAddressEqual,
  keccak256,
  parseTransaction,
} from "viem";
import { humanizeRevertMessage } from "../../utils/error";
import { generateTenderlyParams, tenderlyOrganizationLink } from "./TenderlyTransactionLink";
import { createSignedGasRelayerTransaction, getPctRelayFeeForGSN } from "./utils";

type MachineInterpreter = Interpreter<Context, any, Events, any, any>;
export type MachineSend = MachineInterpreter["send"];
export type MachineState = MachineInterpreter["state"];

export interface TxData {
  data: Hex;
  to: Address;
  value: bigint;
}

export interface SentTransaction {
  data?: Hex;
  from: Address;
  hash: Hex;
  nonce?: number;
  to?: Address;
  value: bigint;
}

interface Context {
  accountAddress?: Address;
  client: PublicClient;
  error?: { title: string; message: string; details: string };
  gasLimit?: bigint;
  gasEstimationError?: Error;
  gasRelayerData?: GasRelayerResponseData;
  gasRelayerEndpoint?: string;
  network?: Network;
  originAddress?: Address;
  paymasterAddress?: Address;
  sentTransaction?: SentTransaction;
  submittedAccountAddress?: Address;
  topUpGasRelayer?: boolean;
  txData?: TxData;
  txDataFn?: (originAddress?: Address) => Promise<TxData> | TxData;
  useGasRelayer?: boolean;
  retryCounter?: number;
  vaultProxy?: Address;
  walletClient?: WalletClient;
}

type EstimatedEvent = DoneInvokeEvent<bigint>;

type SentEvent = DoneInvokeEvent<SentTransaction>;

interface StartEvent {
  type: "START";
  network: Network;
  txDataFn: (originAddress?: Address) => TxData | Promise<TxData>;
  submittedAccountAddress: Address;
  vaultProxy?: Address;
}

interface ResetEvent {
  type: "RESET";
}

interface CancelEvent {
  type: "CANCEL";
}

interface SubmitEvent {
  type: "SUBMIT";
  gasRelayerData?: GasRelayerResponseData;
  gasRelayerEndpoint?: string;
  paymasterAddress?: Address;
  topUpGasRelayer: boolean;
  useGasRelayer: boolean;
  originAddress?: Address;
}

interface AbortEvent {
  type: "ABORT";
}

interface AuthEvent {
  type: "AUTH";
  walletClient?: WalletClient;
  accountAddress?: Address;
}

type Events = AbortEvent | AuthEvent | CancelEvent | EstimatedEvent | ResetEvent | SentEvent | StartEvent | SubmitEvent;

const machineDeclaration: MachineConfig<Context, any, Events> = {
  id: "transactionModal",
  initial: "unknown",
  on: {
    AUTH: {
      actions: "setSigner",
      target: "unknown",
    },
    CANCEL: "cancelled",
  },
  states: {
    building: {
      invoke: {
        onDone: {
          actions: "handleBuildTransactionDone",
          target: "estimating",
        },
        onError: {
          actions: "handleError",
          target: "#transactionModal.error",
        },
        src: "buildTransaction",
      },
    },
    cancelled: {
      entry: "onCancelled",
      on: {
        RESET: {
          target: "idle",
        },
        START: {
          actions: ["handleReset", "handleStart"],
          target: "building",
        },
      },
    },
    error: {
      entry: "onError",
      exit: "handleExitError",
      on: {
        RETRY: {
          actions: "incrementRetryCounter",
          target: "building",
        },
      },
    },
    estimated: {
      on: {
        SUBMIT: {
          actions: "handleSubmit",
          target: "submitted",
        },
      },
    },
    estimating: {
      initial: "pending",
      onDone: "#transactionModal.estimated",
      states: {
        done: {
          type: "final",
        },
        pending: {
          invoke: {
            onDone: {
              actions: "handleEstimateTransactionDone",
              target: "done",
            },
            onError: {
              actions: "handleError",
              target: "#transactionModal.error",
            },
            src: "estimateTransaction",
          },
        },
      },
    },
    idle: {
      entry: "handleReset",
      on: {
        START: {
          actions: "handleStart",
          target: "building",
        },
      },
    },
    sent: {
      entry: "onSent",
      on: {
        RESET: {
          target: "idle",
        },
        START: {
          actions: ["handleReset", "handleStart"],
          target: "building",
        },
      },
    },
    submitted: {
      initial: "validating",
      on: {
        ABORT: {
          target: "#transactionModal.estimated",
        },
        CANCEL: undefined,
      },
      states: {
        delayed: {
          after: {
            FAT_FINGER_DELAY: "sending",
          },
        },
        sending: {
          invoke: {
            onDone: {
              actions: "handleSendingDone",
              target: "#transactionModal.sent",
            },
            onError: {
              actions: "handleError",
              target: "#transactionModal.error",
            },
            src: "sendTransaction",
          },
          on: {
            // Once we are in the `sending` state, there is no turning back.
            ABORT: undefined,
          },
        },
        validating: {
          invoke: {
            onDone: "delayed",
            onError: {
              actions: "handleError",
              target: "#transactionModal.error",
            },
            src: "validateTransaction",
          },
          on: {
            ABORT: {
              target: "#transactionModal.estimated",
            },
            CANCEL: {
              target: "#transactionModal.idle",
            },
          },
        },
      },
    },
    unknown: {
      always: [
        {
          target: "idle",
        },
      ],
    },
  },
};

const machineOptions: Partial<MachineOptions<Context, Events>> = {
  actions: {
    incrementRetryCounter: assign<Context>({
      retryCounter: (context) => (context.retryCounter ?? 0) + 1,
    }),
    handleBuildTransactionDone: assign<Context, ErrorExecutionEvent>({
      txData: (_, event) => event.data,
    }),
    handleError: assign<Context, ErrorExecutionEvent>({
      error: (_, event) => {
        const data: unknown = event.data;

        if (
          data instanceof Error &&
          "details" in data &&
          typeof data.details === "string" &&
          "shortMessage" in data &&
          typeof data.shortMessage === "string"
        ) {
          const { details, shortMessage } = data;
          const errorMessage = extractCallError({ details, shortMessage });

          return {
            ...errorMessage,
            details: errorMessage.message,
          };
        }

        const errorMessage = humanizeRevertMessage(data instanceof Error ? data.message : "");
        return {
          ...errorMessage,
          details: errorMessage.message,
        };
      },
    }),
    handleEstimateTransactionDone: assign<Context, EstimatedEvent>({
      gasLimit: (_, event) => event.data,
    }),
    handleExitError: assign<Context>({
      error: undefined,
    }),
    handleReset: assign<Context>({
      retryCounter: undefined,
      sentTransaction: undefined,
      error: undefined,
      gasLimit: undefined,
      gasRelayerData: undefined,
      network: undefined,
      originAddress: undefined,
      paymasterAddress: undefined,
      submittedAccountAddress: undefined,
      txData: undefined,
      txDataFn: undefined,
      useGasRelayer: undefined,
    }),
    handleSendingDone: assign<Context, SentEvent>({
      sentTransaction: (_, event) => event.data,
    }),

    handleStart: assign<Context, StartEvent>({
      network: (_, event) => event.network,
      submittedAccountAddress: (_, event) => event.submittedAccountAddress,
      txDataFn:
        (_, { txDataFn, submittedAccountAddress }) =>
        async (originAddress?: Address) => {
          const txData = await txDataFn(originAddress);
          return {
            ...txData,
            from: submittedAccountAddress,
            value: txData.value,
          };
        },
      vaultProxy: (_, event) => event.vaultProxy,
    }),
    handleSubmit: assign<Context, SubmitEvent>({
      gasRelayerData: (_, event) => event.gasRelayerData,
      gasRelayerEndpoint: (_, event) => event.gasRelayerEndpoint,
      originAddress: (_, event) => event.originAddress,
      paymasterAddress: (_, event) => event.paymasterAddress,
      topUpGasRelayer: (_, event) => event.topUpGasRelayer,
      useGasRelayer: (_, event) => event.useGasRelayer,
    }),
    setSigner: assign<Context, AuthEvent>({
      accountAddress: (_, event) => event.accountAddress,
      walletClient: (_, event) => event.walletClient,
    }),
  } as any,
  delays: {
    // Grant users a short time period in which they can still abort before
    // the transaction is finally sent.
    FAT_FINGER_DELAY: 2000,
  },
  services: {
    buildTransaction: async (context) => {
      if (!context.txDataFn) {
        throw new Error("Missing transaction function");
      }

      return await context.txDataFn();
    },
    estimateTransaction: async (context) => {
      const { client, walletClient, accountAddress, submittedAccountAddress, txData } = context;

      if (!(accountAddress && walletClient)) {
        throw new Error("Wallet not connected.");
      }

      if (!txData) {
        throw new Error("Missing transaction data");
      }

      if (submittedAccountAddress === undefined || !isAddressEqual(accountAddress, submittedAccountAddress)) {
        throw new Error(
          `Invalid wallet selected. Did you switch account? You are connected with ${accountAddress} but the transaction was created with ${submittedAccountAddress}`,
        );
      }

      try {
        const gas = await client.estimateGas({
          account: submittedAccountAddress,
          ...txData,
        });

        const multiplier = client.chain?.id === Network.POLYGON ? 150n : 115n;

        return (gas * multiplier) / 100n;
      } catch (error) {
        await captureTransactionError(error, context);

        throw error;
      }
    },
    sendTransaction: async (context) => {
      const {
        client,
        gasLimit,
        gasRelayerData,
        gasRelayerEndpoint,
        paymasterAddress,
        originAddress,
        network,
        walletClient,
        accountAddress,
        submittedAccountAddress,
        topUpGasRelayer,
        txDataFn,
        useGasRelayer,
      } = context;

      if (!(accountAddress && walletClient)) {
        throw new Error("Wallet not connected.");
      }

      if (submittedAccountAddress === undefined || !isAddressEqual(accountAddress, submittedAccountAddress)) {
        throw new Error(
          `Invalid wallet selected. Did you switch account? You are connected with ${accountAddress} but the transaction was created with ${submittedAccountAddress}`,
        );
      }

      if (!txDataFn) {
        throw new Error("Missing txData function");
      }

      // If txDataFn is defined, rebuild txData with originAddress
      const txData = await txDataFn(originAddress);

      if (useGasRelayer) {
        if (!(paymasterAddress && gasRelayerData && gasRelayerEndpoint)) {
          throw new Error("Missing Gas Relayer Data");
        }

        const { relayWorkerAddress, relayHubAddress, maxAcceptanceBudget, minGasPrice, ready } = gasRelayerData;

        if (!ready) {
          throw new Error("Gas relayer not ready. Please try submitting the transaction again.");
        }

        if (!gasLimit) {
          throw new Error("Missing gas limit");
        }

        const { gasRelayerPayload } = await createSignedGasRelayerTransaction({
          gasLimit,
          gasPrice: (minGasPrice * 110n) / 100n,
          maxAcceptanceBudget,
          paymasterData: encodeAbiParameters([{ type: "bool" }], [!!topUpGasRelayer]),
          pctRelayFee: network && getPctRelayFeeForGSN(network),
          relayHub: relayHubAddress,
          relayWorker: relayWorkerAddress,
          txData,
          vaultPaymaster: paymasterAddress,
          client,
          walletClient,
        });

        const endpoint = `${gasRelayerEndpoint}/gsn1/relay`;

        const response = await (
          await fetch(endpoint, {
            body: gasRelayerPayload,
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          })
        ).json();

        const signedTx = response?.signedTx;

        if (typeof signedTx === "undefined") {
          if (typeof response?.error === "string") {
            throw new Error(`An error occurred: ${response.error}`);
          }

          throw new Error("An error occurred in the relayer. Please try again.");
        }

        const parsedTx = parseTransaction(response.signedTx);

        const sentTransaction: SentTransaction = {
          from: submittedAccountAddress,
          hash: keccak256(response.signedTx),
          nonce: parsedTx.nonce,
          data: parsedTx.data,
          to: parsedTx.to ?? undefined,
          value: parsedTx.value ?? 0n,
        };

        return sentTransaction;
      }

      const preparedTx = await client.prepareTransactionRequest({
        account: submittedAccountAddress,
        chain: walletClient.chain,
        data: txData.data,
        to: txData.to,
        value: txData.value ?? 0n,
        gas: gasLimit,
      });

      const hash = await walletClient.sendTransaction({
        chain: walletClient.chain,
        account: submittedAccountAddress,
        ...txData,
      });

      const sentTransaction: SentTransaction = {
        from: submittedAccountAddress,
        hash,
        nonce: preparedTx.nonce,
        data: preparedTx.data,
        to: preparedTx.to ?? undefined,
        value: preparedTx.value ?? 0n,
      };

      return sentTransaction;
    },
    validateTransaction: async (context) => {
      const { gasLimit, client, walletClient, accountAddress, submittedAccountAddress, txData } = context;

      if (!(accountAddress && walletClient)) {
        throw new Error("Wallet not connected.");
      }

      if (submittedAccountAddress === undefined || !isAddressEqual(accountAddress, submittedAccountAddress)) {
        throw new Error(
          `Invalid wallet selected. Did you switch account? You are connected with ${accountAddress} but the transaction was created with ${submittedAccountAddress}.`,
        );
      }

      if (!txData) {
        throw new Error("Missing transaction data.");
      }

      try {
        await client.call({
          ...txData,
          account: submittedAccountAddress,
          gas: gasLimit,
        });
      } catch (error) {
        await captureTransactionError(error, context);

        throw error;
      }
    },
  },
};

export const Machine = createMachine<Context, Events>(machineDeclaration, machineOptions);

async function captureTransactionError(error: CallErrorType, context: Context) {
  // Only log errors if this is the first try.
  if (!context.retryCounter && context.txData) {
    const decoded = extractCallError(error);
    const block = Number(await context.client.getBlockNumber());
    const tenderlyParams = generateTenderlyParams({
      from: context.accountAddress,
      network: context.network,
      txData: context.txData,
      block,
    });

    Sentry.withScope((scope) => {
      // Group errors based on the current page and error title for better categorization
      scope.setFingerprint([
        "{{ default }}",
        decoded.title,
        window.location.pathname
          .split("/")
          .map((segment) =>
            context.vaultProxy && isAddress(segment) && isAddressEqual(segment, context.vaultProxy)
              ? "VAULT_ADDRESS"
              : segment,
          )
          .join("/"), // Remove specific vault address from the path to group errors from different vaults together
      ]);

      Sentry.captureException(error, {
        extra: {
          tenderly: `${tenderlyOrganizationLink}${tenderlyParams}`,
        },
        tags: {
          title: decoded.title === "Error" ? "Uncategorized error" : decoded.title,
          message: decoded.message,
          signer: context.accountAddress,
          network: networks[context.network ?? Network.ETHEREUM].label,
        },
      });
    });
  }
}
