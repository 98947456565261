import { LoadingBoundary } from "components/common/LoadingBoundary";
import { ProtocolBanner } from "components/vault/protocols/ProtocolBanner";
import { VaultPendleV2Navigation } from "components/vault/protocols/pendle-v2/VaultPendleV2Navigation";
import { Outlet } from "react-router";

export function VaultPendleV2Layout() {
  return (
    <>
      <ProtocolBanner integrationId="pendle-v2" />

      <VaultPendleV2Navigation />
      <LoadingBoundary>
        <Outlet />
      </LoadingBoundary>
    </>
  );
}
