import { BigIntDisplay, TokenLabel } from "@enzymefinance/ethereum-ui";

import { Currency } from "@enzymefinance/environment";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { Alert } from "@enzymefinance/ui";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useNetwork } from "components/providers/NetworkProvider";
import { useMarketInfoGmxv2Query } from "queries/backend";
import { client } from "utils/backend";
import { isAddressEqual, maxUint256 } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const gmxV2CreateOrder: ExternalPositionHandler<Integrations.GMXV2.CreateOrderArgs> = {
  Description({ args: { addresses, numbers, orderType, isLong } }) {
    const { environment, deployment } = useNetwork();

    const marketInfoQuery = useMarketInfoGmxv2Query({
      client,
      variables: { market: addresses.market, network: deployment },
    });

    const asset = environment.getAsset(addresses.initialCollateralToken);

    if (marketInfoQuery.loading) {
      return <LoadingScreen />;
    }

    if (marketInfoQuery.error) {
      return <Alert appearance="error">{marketInfoQuery.error.message}</Alert>;
    }

    if (!marketInfoQuery.data?.marketInfoGMXV2) {
      return <Alert appearance="error">Market info not found</Alert>;
    }

    const displayTriggerPrice =
      orderType === Integrations.GMXV2.OrderType.StopLossDecrease ||
      orderType === Integrations.GMXV2.OrderType.LimitDecrease;

    const isAnyAcceptablePrice = isLong ? numbers.acceptablePrice === 0n : numbers.acceptablePrice === maxUint256;

    const displayMinOutputAmount = orderType === Integrations.GMXV2.OrderType.MarketDecrease;

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Market</span>{" "}
          <span className="text-high-emphasis">
            {isLong ? "Long" : "Short"} {marketInfoQuery.data.marketInfoGMXV2.indexTokenName}
          </span>
        </div>
        <div className="flex flex-row justify-between">
          <span>Collateral Delta</span>
          <BigIntDisplay
            value={
              isAddressEqual(asset.id, environment.namedTokens.nativeTokenWrapper.id) &&
              orderType === Integrations.GMXV2.OrderType.MarketIncrease
                ? numbers.initialCollateralDeltaAmount - numbers.executionFee
                : numbers.initialCollateralDeltaAmount
            }
            numberFormat={{ currency: asset.symbol, maximumSignificantDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Size Delta</span>
          <BigIntDisplay
            value={numbers.sizeDeltaUsd}
            numberFormat={{ currency: Currency.USD, maximumSignificantDigits: 4 }}
            decimals={Integrations.GMXV2.usdDecimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Acceptable Price</span>
          {isAnyAcceptablePrice ? (
            <span className="text-high-emphasis">Any</span>
          ) : (
            <BigIntDisplay
              value={numbers.acceptablePrice}
              numberFormat={{ currency: Currency.USD, maximumSignificantDigits: 4 }}
              decimals={Integrations.GMXV2.usdDecimals - marketInfoQuery.data.marketInfoGMXV2.indexTokenDecimals}
            />
          )}
        </div>
        {displayTriggerPrice ? (
          <div className="flex flex-row justify-between">
            <span>Trigger Price</span>
            <BigIntDisplay
              value={numbers.triggerPrice}
              numberFormat={{ currency: Currency.USD, maximumSignificantDigits: 4 }}
              decimals={Integrations.GMXV2.usdDecimals - marketInfoQuery.data.marketInfoGMXV2.indexTokenDecimals}
            />
          </div>
        ) : null}
        {displayMinOutputAmount ? (
          <div className="flex flex-row justify-between">
            <span>Min Output Amount</span>
            <BigIntDisplay
              value={numbers.minOutputAmount}
              numberFormat={{ currency: Currency.USD, maximumSignificantDigits: 4 }}
              decimals={Integrations.GMXV2.usdDecimals}
            />
          </div>
        ) : null}
        <div className="flex flex-row justify-between">
          <span>Execution Fee</span>
          <BigIntDisplay
            value={numbers.executionFee}
            numberFormat={{
              currency: environment.namedTokens.nativeTokenWrapper.symbol,
              minimumSignificantDigits: 6,
              maximumSignificantDigits: 6,
            }}
            decimals={environment.namedTokens.nativeTokenWrapper.decimals}
          />
        </div>
      </div>
    );
  },
  Label({ args: { orderType } }) {
    switch (orderType) {
      case Integrations.GMXV2.OrderType.MarketIncrease:
        return <>Market Increase</>;
      case Integrations.GMXV2.OrderType.MarketDecrease:
        return <>Market Decrease</>;
      case Integrations.GMXV2.OrderType.StopLossDecrease:
        return <>Stop Loss</>;
      case Integrations.GMXV2.OrderType.LimitDecrease:
        return <>Take Profit</>;
      case Integrations.GMXV2.OrderType.Liquidation:
        return undefined;
      default:
        Assertion.never(orderType, "Unknown order type");
    }
  },
  decodeExternalPositionArgs: Integrations.GMXV2.createOrderDecode,
};

export const gmxV2UpdateOrder: ExternalPositionHandler<Integrations.GMXV2.UpdateOrderArgs> = {
  Description() {
    return null;
  },
  Label() {
    return <>Update Order</>;
  },
  decodeExternalPositionArgs: Integrations.GMXV2.updateOrderDecode,
};

export const gmxV2CancelOrder: ExternalPositionHandler<Integrations.GMXV2.CancelOrderArgs> = {
  Description() {
    return null;
  },
  Label() {
    return <>Cancel Order</>;
  },
  decodeExternalPositionArgs: Integrations.GMXV2.cancelOrderDecode,
};

export const gmxV2ClaimFundingFees: ExternalPositionHandler<Integrations.GMXV2.ClaimFundingFeesArgs> = {
  Description({ args: { tokens, markets } }) {
    const { environment } = useNetwork();

    const assets = tokens.map((token) => environment.getAsset(token));

    return (
      <div className="flex space-y-4 flex-col">
        {assets.map((asset, index) => {
          const market = markets[index];
          return <TokenLabel asset={asset} key={`${asset.id}-${market}`} />;
        })}
      </div>
    );
  },
  Label() {
    return <>Claim Funding Fees</>;
  },
  decodeExternalPositionArgs: Integrations.GMXV2.claimFundingFeesDecode,
};

export const gmxV2ClaimCollateral: ExternalPositionHandler<Integrations.GMXV2.ClaimCollateralArgs> = {
  Description({ args: { tokens, markets } }) {
    const { environment } = useNetwork();

    const assets = tokens.map((token) => environment.getAsset(token));

    return (
      <div className="flex space-y-4 flex-col">
        {assets.map((asset, index) => {
          const market = markets[index];
          return <TokenLabel asset={asset} key={`${asset.id}-${market}`} />;
        })}
      </div>
    );
  },
  Label() {
    return <>Claim Collateral</>;
  },
  decodeExternalPositionArgs: Integrations.GMXV2.claimCollateralDecode,
};

export const gmxV2Sweep: ExternalPositionHandler = {
  Description() {
    return null;
  },
  Label() {
    return <>Sweep assets</>;
  },
  decodeExternalPositionArgs: () => {},
};

export const createGMXV2ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize GMX V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = gmxV2CreateOrder.decodeExternalPositionArgs(actionArgs);

    return <gmxV2CreateOrder.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize GMX V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = gmxV2CreateOrder.decodeExternalPositionArgs(actionArgs);

    return <gmxV2CreateOrder.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize GMX V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = gmxV2CreateOrder.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(gmxV2CreateOrder.Label, gmxV2CreateOrder.Description);

    return <Summary args={args} />;
  },
};
