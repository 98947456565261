import { Checkbox } from "@enzymefinance/hook-form";
import { Vault } from "@enzymefinance/sdk";
import { getEnabledPolicies } from "@enzymefinance/sdk/Configuration";
import { Badge } from "@enzymefinance/ui";
import { isAddressEqual } from "viem";
import { z } from "zod";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { VaultConfig, VaultConfigDisplayProps } from "../VaultConfigTypes";
import { VaultConfigType } from "../VaultConfigTypes";

export const onlyUntrackDustOrPricelessAssetsPolicySchema = z.boolean();

function onlyUntrackDustOrPricelessAssetsPolicyFormFields() {
  return (
    <Checkbox
      label={onlyUntrackDustOrPricelessAssetsPolicy.label}
      name={VaultConfigFieldName.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY}
    />
  );
}

function onlyUntrackDustOrPricelessAssetsPolicyDisplay({
  settings,
}: VaultConfigDisplayProps<VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY>) {
  return settings ? (
    <p>
      This vault is only permitted to remove asset positions from its books (and thereby remove them from share price
      calculations) if their value can be considered negligible.
    </p>
  ) : (
    <p>Disabled</p>
  );
}

function onlyUntrackDustOrPricelessAssetsPolicyDisplaySubgraph() {
  return null;
}

export const onlyUntrackDustOrPricelessAssetsPolicy: VaultConfig<VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY> =
  {
    address: (contracts) => contracts.OnlyUntrackDustOrPricelessAssetsPolicy,
    disableable: false,
    display: onlyUntrackDustOrPricelessAssetsPolicyDisplay,
    displaySubgraph: onlyUntrackDustOrPricelessAssetsPolicyDisplaySubgraph,
    editable: false,
    // This policy has no setting.
    encode: () => "0x",
    fetch: async ({ comptroller, client, vaultConfigAddress }) => {
      const policyManager = await Vault.getPolicyManager(client, { comptrollerProxy: comptroller });
      const enabledPolicies = await getEnabledPolicies(client, { comptrollerProxy: comptroller, policyManager });

      return enabledPolicies.some((policy) => isAddressEqual(vaultConfigAddress, policy));
    },
    formFields: onlyUntrackDustOrPricelessAssetsPolicyFormFields,
    label: "Restrict Asset Position Removal",
    managerDescription: (
      <div className="space-y-4">
        <p>
          Prevents the removal of an asset position from the vault&rsquo;s books only unless a) it does not have a valid
          price or b) its value can be considered negligible (i.e., dust). The dust threshold is maintained by the
          Enzyme Council.
        </p>
        <p>
          There are two ways your vault can hold assets; directly or in an external position. Your vault&rsquo;s share
          price is calculated based on the assets it holds in either of these methods that are &rsquo;tracked&rsquo; by
          the smart contract. Assets are automatically tracked if they are added via a third-party defi protocol, and
          untracked if their balance falls to 0. By default, assets can be manually tracked and untracked regardless of
          their balance, which may concern some depositors that assets with real value could be &rsquo;hidden&rsquo;
          from the vault.
        </p>
        <p>
          <InlineLink
            to="https://specs.enzyme.finance/topics/policies#onlyuntrackdustorpricelessassetspolicy"
            appearance="link"
          >
            This policy
          </InlineLink>{" "}
          restricts your ability to manually untrack assets in external positions which have a balance greater than the
          dust threshold set by the Enzyme Council. Implementing it will assure your depositors that you cannot
          manipulate the share price of your vault by arbitrarily untracking assets with a material value.
        </p>
        <Badge appearance="warning">Semi-permanent Setting</Badge>
      </div>
    ),
    publicDescription: (
      <p>
        <InlineLink
          to="https://specs.enzyme.finance/topics/policies#onlyuntrackdustorpricelessassetspolicy"
          appearance="link"
        >
          This policy
        </InlineLink>{" "}
        eliminates the potential for a vault manager to manipulate their vault&rsquo;s share price by undercounting the
        assets it holds.
      </p>
    ),
    type: VaultConfigType.ONLY_UNTRACK_DUST_OR_PRICELESS_ASSETS_POLICY,
    validationSchema: onlyUntrackDustOrPricelessAssetsPolicySchema,
    usePricelessAssetBypass: true,
  };
