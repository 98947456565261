import { Environment } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { useGlobals } from "components/providers/GlobalsProvider";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const staderWithdrawalsRequestWithdrawal: ExternalPositionHandler<Integrations.Stader.RequestWithdrawalsArgs> = {
  Description({ args: { amount } }) {
    const { environment } = useGlobals();

    Assertion.invariant(Environment.isDeploymentEthereum(environment), "Unsupported environment");

    const { ethx } = environment.namedTokens;

    return (
      <div className="flex flex-col space-y-4">
        <p>
          Requesting to withdraw{" "}
          <BigIntDisplay
            value={amount}
            decimals={ethx.decimals}
            numberFormat={{ currency: ethx.symbol, maximumFractionDigits: ethx.decimals }}
          />{" "}
          on Stader.
        </p>
      </div>
    );
  },
  Label() {
    return <>Request Stader Withdrawal</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Stader.requestWithdrawalsDecode(encodedCallArgs),
};

export const staderWithdrawalsClaimWithdrawals: ExternalPositionHandler<Integrations.Stader.ClaimWithdrawalsArgs> = {
  Description({ args: { requestId } }) {
    return (
      <div className="flex flex-col space-y-4">
        <p>Claiming a withdrawal request on Stader.</p>
        <div className="divide-y-2 divide-base-content">
          <div key={requestId} className="py-4 flex items-center justify-between">
            <span>Request ID:</span>
            <span>{requestId.toString()}</span>
          </div>
        </div>
      </div>
    );
  },
  Label() {
    return <>Claim</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Stader.claimWithdrawalsDecode(encodedCallArgs),
};

export const createStaderWithdrawalExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Stader Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = staderWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    return <staderWithdrawalsRequestWithdrawal.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Stader Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = staderWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    return <staderWithdrawalsRequestWithdrawal.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Stader Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = staderWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(
      staderWithdrawalsRequestWithdrawal.Label,
      staderWithdrawalsRequestWithdrawal.Description,
    );

    return <Summary args={args} />;
  },
};
