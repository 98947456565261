import { Address, BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { IntegrationHandler } from "./types";

export const buyPrincipalToken: IntegrationHandler<Integrations.PendleV2.BuyPrincipleTokenWithAdapterArgs> = {
  Description({ args: { depositTokenAddress, depositAmount, market } }) {
    const { environment } = useGlobals();

    const depositAsset = environment.getAsset(depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={depositAmount}
            numberFormat={{ currency: depositAsset.symbol, maximumFractionDigits: 8 }}
            decimals={depositAsset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Fixed Yield</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.PendleV2.buyPrincipleTokenWithAdapterDecode(encodedCallArgs),
};

export const sellPrincipalToken: IntegrationHandler<Integrations.PendleV2.SellPrincipleTokenWithAdapterArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.withdrawalTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.withdrawalAmount}
            numberFormat={{ currency: asset.symbol, maximumFractionDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Withdraw Fixed Yield</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) =>
    Integrations.PendleV2.sellPrincipleTokenWithAdapterDecode(encodedCallArgs),
};

export const addLiquidity: IntegrationHandler<Integrations.PendleV2.AddLiquidityWithAdapterArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.depositTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.depositAmount}
            numberFormat={{ currency: asset.symbol, maximumFractionDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Deposit Liquidity</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.PendleV2.addLiquidityWithAdapterDecode(encodedCallArgs),
};

export const removeLiquidity: IntegrationHandler<Integrations.PendleV2.RemoveLiquidityWithAdapterArgs> = {
  Description({ args }) {
    const { environment } = useGlobals();

    const asset = environment.getAsset(args.withdrawalTokenAddress);

    return (
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <span>Amount</span>
          <BigIntDisplay
            value={args.withdrawalAmount}
            numberFormat={{ currency: asset.symbol, maximumFractionDigits: 8 }}
            decimals={asset.decimals}
          />
        </div>
        <div className="flex flex-row justify-between">
          <span>Market</span> <Address address={args.market} trimmed={true} />
        </div>
      </div>
    );
  },
  Label() {
    return <>Withdraw Liquidity</>;
  },
  decodeIntegrationArgs: (encodedCallArgs) => Integrations.PendleV2.removeLiquidityWithAdapterDecode(encodedCallArgs),
};
