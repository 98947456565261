import { Environment } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { NumberDisplay } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const lidoWithdrawalsRequestWithdrawal: ExternalPositionHandler<Integrations.Lido.RequestWithdrawalsArgs> = {
  Description({ args: { amounts } }) {
    const { environment } = useGlobals();

    Assertion.invariant(Environment.isDeploymentEthereum(environment), "Unsupported environment");

    const { steth } = environment.namedTokens;

    const totalAmount = amounts.reduce((acc, amount) => acc + amount, 0n);

    return (
      <div className="flex flex-col space-y-4">
        <p>
          Requesting to withdraw{" "}
          <BigIntDisplay
            value={totalAmount}
            decimals={steth.decimals}
            numberFormat={{ currency: steth.symbol, maximumFractionDigits: steth.decimals }}
          />{" "}
          on Lido.
        </p>
      </div>
    );
  },
  Label() {
    return <>Request Lido Withdrawal</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Lido.requestWithdrawalsDecode(encodedCallArgs),
};

export const lidoWithdrawalsClaimWithdrawals: ExternalPositionHandler<Integrations.Lido.ClaimWithdrawalsArgs> = {
  Description({ args: { requestIds } }) {
    return (
      <div className="flex flex-col space-y-4">
        <p>
          Claiming <NumberDisplay value={requestIds.length} /> withdrawal requests on Lido.
        </p>
        <div className="divide-y-2 divide-base-content">
          {requestIds.map((requestId) => (
            <div key={requestId} className="py-4 flex items-center justify-between">
              <span>Request ID:</span>
              <span>{requestId.toString()}</span>
            </div>
          ))}
        </div>
      </div>
    );
  },
  Label() {
    return <>Claim</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.Lido.claimWithdrawalsDecode(encodedCallArgs),
};

export const createLidoWithdrawalExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Lido Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = lidoWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    return <lidoWithdrawalsRequestWithdrawal.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Lido Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = lidoWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    return <lidoWithdrawalsRequestWithdrawal.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Lido Withdrawal external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = lidoWithdrawalsRequestWithdrawal.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(
      lidoWithdrawalsRequestWithdrawal.Label,
      lidoWithdrawalsRequestWithdrawal.Description,
    );

    return <Summary args={args} />;
  },
};
