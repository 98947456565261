import { isDivaVault } from "@enzymefinance/diva-staking";

import type { NavigationItemProps } from "@enzymefinance/ui";
import { Navigation } from "@enzymefinance/ui";
import type { LinkProps } from "components/routing/Link";
import { Link } from "components/routing/Link";
import { useVault } from "components/vault/VaultProvider";
import { useVaultRewardsCampaignQuery } from "queries/backend";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { client } from "utils/backend";
import { debug } from "utils/config";

export function VaultNavigation() {
  const { id } = useVault();
  const { whiteLabelId } = useParams<"whiteLabelId">();

  const routeTo = useMemo(() => `${whiteLabelId ? `/whitelabel/${whiteLabelId}` : ""}/vault/${id}`, [id, whiteLabelId]);
  const vaultRewardsCampaignQuery = useVaultRewardsCampaignQuery({
    client,
    variables: { vaultAddress: id },
  });

  const hasRewardsCampaign = !!vaultRewardsCampaignQuery.data?.vaultRewardsCampaign;

  return (
    <nav aria-label="Vault Diligence">
      <Navigation direction="horizontal">
        <VaultNavigationItem to={routeTo}>Overview</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/portfolio`}>Portfolio</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/financials`}>Financials</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/fees`}>Fees</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/policies`}>Policies</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/depositors`}>Depositors</VaultNavigationItem>
        <VaultNavigationItem to={`${routeTo}/activity`}>Activity</VaultNavigationItem>{" "}
        <VaultNavigationItem to={`${routeTo}/my-deposit`}>My Deposit</VaultNavigationItem>{" "}
        {hasRewardsCampaign ? (
          <VaultNavigationItem to={`${routeTo}/rewards-campaign`}>Rewards</VaultNavigationItem>
        ) : null}
        {isDivaVault(id) ? (
          <>
            <VaultNavigationItem to={`${routeTo}/rewards-diva`}>Rewards</VaultNavigationItem>
            {debug ? <VaultNavigationItem to={`${routeTo}/migrate`}>Migrate</VaultNavigationItem> : null}
          </>
        ) : null}
      </Navigation>
    </nav>
  );
}

type VaultNavigationItemProps = Omit<NavigationItemProps<LinkProps>, "as" | "isCurrent">;

function VaultNavigationItem(props: VaultNavigationItemProps) {
  const location = useLocation();
  const isCurrent = location.pathname === props.to.toString();

  return <Navigation.Item {...props} as={Link} isCurrent={isCurrent} />;
}
